import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useGetExperiencesByVenueQuery } from '@sevenrooms/core/api'
import { ExperienceStatusEnum, OfferTypeEnum } from '@sevenrooms/core/domain'
import { setExperiences } from '../../actions/BookAvailabilityActions'
import { useStoreSelector } from '../../selectors/useStoreSelector'

export function useGetInternalBookingExperiencesQuery() {
  const dispatch = useDispatch()
  const { searchVenues } = useStoreSelector(state => state.bookAvailabilityState)
  const venueIds = useMemo(() => searchVenues.map(venue => venue.id), [searchVenues])

  const { isSuccess, isFetching, data } = useGetExperiencesByVenueQuery({
    venueIds,
    status: ExperienceStatusEnum.ACTIVE,
    excludedOfferTypes: [OfferTypeEnum.EVENT],
  })

  const [allExperiences, experiencesByVenue] = useMemo(() => {
    const nonNullData = isSuccess && !isFetching && data != null ? data : {}
    const entries = Object.entries(nonNullData)
    const allExperiences = entries.flatMap(([_venueId, experiences]) => experiences.results)
    const experiencesByVenue = Object.fromEntries(entries.map(([venueId, experiences]) => [venueId, experiences.results] as const))
    return [allExperiences, experiencesByVenue] as const
  }, [isSuccess, isFetching, data])

  useEffect(() => {
    dispatch(setExperiences(allExperiences))
  }, [allExperiences, dispatch])

  return useMemo(
    () => ({
      experiencesByVenue,
      allExperiences,
      isSuccess,
      isFetching,
    }),
    [experiencesByVenue, allExperiences, isSuccess, isFetching]
  )
}
