import type { ReservationWidget } from '@sevenrooms/core/domain'
import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { Totals, Amount, Charges, ChargesCategory, TaxesAndServices, TaxesAndServicesCategory } from './components'
import { paymentMessages } from './Payment.locales'
import type { PaymentForm } from './Payment.zod'
import type { PropsWithChildren } from 'react'

export interface PaymentProps {
  field: Field<PaymentForm>
  oldValues?: PaymentForm
  oldFulfilledValues?: PaymentForm
  override: boolean
  title?: string
  selectable?: boolean
  taxGroups: ReservationWidget.TaxGroup[]
  currencyCode: string
  currencySymbol: string
}

export function Payment({ field, title, selectable, oldValues, oldFulfilledValues, ...props }: PaymentProps) {
  const { override } = props
  const { formatMessage } = useLocales()
  const [categories, categoriesBundled] = useWatchMany(field, ['categories', 'categoriesBundled'])
  // these arrays need to be deterministic because they re-render when charges are updated and can switch order causing
  // unexpected behavior
  const categoriesBundledEntries = Object.entries(categoriesBundled).sort((a, b) =>
    a[1].name.toLowerCase().localeCompare(b[1].name.toLowerCase())
  )
  const categoriesEntries = Object.entries(categories).sort((a, b) => a[1].name.toLowerCase().localeCompare(b[1].name.toLowerCase()))

  return (
    <VStack>
      <Group title={formatMessage(paymentMessages.chargesPriceTitle)}>
        <Amount
          field={field.prop('amount')}
          title={title ?? formatMessage(paymentMessages.amount)}
          selectable={selectable}
          oldValue={oldValues?.amount}
          {...props}
        />
        <Charges
          field={field}
          selectable={selectable}
          oldValue={oldValues?.charges}
          clientSelectGratuity={field.prop('clientSelectGratuity')}
          {...props}
        />
        {categoriesBundledEntries.map(([catId, { upgrades }]) => (
          <>
            {Object.entries(upgrades).map(([id, { name, count }]) => (
              <Amount
                key={`${catId}${id}`}
                field={field.prop(`categoriesBundled.${catId}.upgrades.${id}.amount`)}
                oldValue={oldValues?.categoriesBundled?.[catId]?.upgrades?.[id]?.amount}
                title={name}
                count={count}
                {...props}
              />
            ))}
            <ChargesCategory
              field={field.prop(`categoriesBundled.${catId}`)}
              selectable={selectable}
              oldValue={oldValues?.categoriesBundled?.[catId]?.charges}
              clientSelectGratuity={field.prop('clientSelectGratuity')}
              {...props}
            />
          </>
        ))}
        {(!selectable || categoriesBundledEntries.length > 0) && <TaxesAndServices field={field} {...props} />}
      </Group>
      {categoriesEntries.map(([catId, { name, upgrades }]) => (
        <Group key={catId} title={formatMessage(paymentMessages.chargesUpgradesTitle, { name })}>
          {Object.entries(upgrades).map(([id, { name, count }]) => (
            <Amount
              key={id}
              field={field.prop(`categories.${catId}.upgrades.${id}.amount`)}
              oldValue={oldValues?.categories?.[catId]?.upgrades?.[id]?.amount}
              title={name}
              count={count}
              {...props}
            />
          ))}
          <ChargesCategory
            field={field.prop(`categories.${catId}`)}
            selectable={selectable}
            oldValue={oldValues?.categories?.[catId]?.charges}
            clientSelectGratuity={field.prop('clientSelectGratuity')}
            {...props}
          />
          {!override && <TaxesAndServicesCategory field={field.prop(`categories.${catId}`)} {...props} />}
        </Group>
      ))}
      <Totals field={field} oldValues={oldValues} oldFulfilledValues={oldFulfilledValues} {...props} />
    </VStack>
  )
}

interface GroupProps {
  title: string
}

function Group({ title, children }: PropsWithChildren<GroupProps>) {
  return (
    <VStack borderTopColor="borders" mb="m">
      <Box p="s m" backgroundColor="secondaryBackground">
        <Text textStyle="body1Bold">{title}</Text>
      </Box>
      <Box>{children}</Box>
    </VStack>
  )
}
