import { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { useGetActiveAudiencesByVenueQuery } from '@sevenrooms/core/api'
import { TimeslotDefaults } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Toggle, StyledLabel } from '@sevenrooms/core/ui-kit/core'
import { notNullish } from '@sevenrooms/core/ui-kit/form'
import { Grid, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useStoreSelector } from '../../selectors/useStoreSelector'
import { AvailabilityLocales } from './Availability.locales'
import { getShiftTimeslots } from './helpers'
import { useGetInternalBookingExperiencesQuery } from './useGetInternalBookingExperiencesQuery'
import { useGetItemFromTimeslot } from './useGetItemFromTimeslot'
import { useMultiVenueAvailabilityTimeslotsRequest } from './useMultiVenueAvailabilityTimeslotsRequest'
import type * as BookAvailabilityActions from '../../actions/BookAvailabilityActions'

interface InternalArBookingControlsProps {
  actions: typeof BookAvailabilityActions
}

export function InternalArBookingBar({ actions }: InternalArBookingControlsProps) {
  const { formatMessage } = useLocales()
  const getItemFromTimeslot = useGetItemFromTimeslot()
  const { showAccessRules, searchVenues, audienceId, typeId, shiftPersistentId } = useStoreSelector(state => state.bookAvailabilityState)
  const venueIds = useMemo(() => searchVenues.map(venue => venue.id), [searchVenues])

  const { data: audiencesbyVenue, isSuccess } = useGetActiveAudiencesByVenueQuery(venueIds)
  const { allExperiences: experiences } = useGetInternalBookingExperiencesQuery()
  const { availabilityARByVenue } = useMultiVenueAvailabilityTimeslotsRequest()

  const audiences = useMemo(() => {
    const audienceEntries = Object.values(audiencesbyVenue ?? {})
      .flatMap(audiences => audiences ?? [])
      .map(audience => [audience.value, audience] as const)

    const audiencesByValue = Object.fromEntries(audienceEntries)
    return Object.values(audiencesByValue)
  }, [audiencesbyVenue])

  const typesChoices = useMemo(() => {
    const availability = Object.values(availabilityARByVenue ?? {}).flat()
    const { timeslots } = getShiftTimeslots(availability, shiftPersistentId)

    const offerNames =
      timeslots
        ?.map(timeslot => timeslot.experience_id)
        ?.map(experienceId => experiences.find(experience => experience.id === experienceId))
        ?.filter(notNullish)
        ?.map(experience => experience.name) ?? []

    const byOfferChoices = [...new Set(offerNames)].map(name => ({ name, value: name }))

    const typesNames =
      timeslots?.map(t => {
        const item = getItemFromTimeslot(t)
        return item.name || formatMessage(AvailabilityLocales.noTimeslotDescriptionTypeName)
      }) ?? []

    const types = [...new Set(typesNames)].map(name => ({ name, value: name }))
    return [
      { choices: [{ name: formatMessage(AvailabilityLocales.allTypesName), value: TimeslotDefaults.ALL }] },
      { name: formatMessage(AvailabilityLocales.byPublicDescription), choices: types },
      { name: formatMessage(AvailabilityLocales.byOffer), choices: byOfferChoices },
    ]
  }, [formatMessage, getItemFromTimeslot, experiences, availabilityARByVenue, shiftPersistentId])

  useEffect(() => {
    if (!audienceId && audiences.length > 0) {
      actions.changeAudience(audiences[0]?.value)
    }
  }, [audienceId, audiences, actions])

  if (isSuccess && audiences.length === 0) {
    return null
  }

  return (
    <FieldGroup gridTemplateColumns="repeat(3, minmax(0, 1fr))" pr="m">
      <HStack alignItems="center" height={46} pr="xs">
        <Toggle
          name="toggle-show-access-rules"
          label={
            <HStack spacing="s" alignItems="center">
              <Text>Show Access Rules</Text>
            </HStack>
          }
          checked={showAccessRules}
          onChange={actions.changeShowAccessRules}
        />
      </HStack>
      {showAccessRules && (
        <>
          <DropdownArrowsPicker
            name="AUDIENCE"
            choices={audiences}
            value={audienceId}
            onChangeHandler={actions.changeAudience}
            {...dropdownCommonProps}
          />
          <DropdownArrowsPicker
            name="TYPE"
            choices={typesChoices}
            value={typeId}
            onChangeHandler={actions.changeType}
            {...dropdownCommonProps}
          />
        </>
      )}
    </FieldGroup>
  )
}

const dropdownCommonProps = {
  placeholder: 'LOADING',
  showNavigation: false,
  isLightTheme: true,
  style: { width: 'auto', margin: 0 },
}

const FieldGroup = styled(Grid)`
  column-gap: 9px;
  margin: 15px 12px;
  ${props => props.theme.clearFix};

  ${StyledLabel} div:first-child {
    margin-right: 6px;
  }
`
