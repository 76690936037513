import priceFormatter from 'currency-formatter'
import _ from 'lodash'
import moment from 'moment-timezone'
import money from 'money-math'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import { TransactionTypes } from 'mgr/lib/utils/Constants'
import { getVenueLocalTime } from 'svr/common/TimeUtil'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import { CreditCardCollectionOptionsEnum } from '@sevenrooms/core/domain'
import * as ActionTypes from '../actions/ActionTypes'
import { getPreviouslyChargedAmount } from '../actions/BookAvailabilityActions'
import { currencyFormatter } from '../utils/transformPaymentData'

export const initialState = {
  cardEntryOption: 'manual',
  cardHolderName: '',
  cardHolderNumber: '',
  cardExpMonth: 0,
  cardExpYear: 0,
  cardCcv: '',
  cardToken: '',
  cardData: null,
  currencyCode: 'USD',
  currencySymbol: '$',
  formattedChargeAmount: '',
  chargeAmount: '',
  chargeAmountDiff: 0,
  chargeTotal: '',
  chargeApplyTax: false,
  chargeTax: '0',
  taxAmount: '',
  chargeDescription: '',
  taxGroupId: null,
  stripeInstance: null,
  stripeCardElement: null,
  applyServiceCharge: false,
  serviceCharge: '',
  serviceChargeAmount: '',
  applyGratuityCharge: false,
  gratuityCharge: '',
  gratuityChargeAmount: '',
  requiredGratuityCharge: false,
  gratuityType: undefined,
  paylinkGratuityType: 'gratuity_percentage',
  paymentPublicToken: '',
  selectedCardId: null,
  useGuestProfilePhoneNumber: true,
  cardPhoneCountry: '',
  cardPhoneNumber: '',
  resCardId: '',
  resCardLast4: '',
  resCardType: '',
  override: false,
  takePaymentOrSave: 'none',
  chargeType: '',
  additionalReservationCards: [],
  cardRequired: false,
  paymentRule: null,
  partySizeMinRule: 0,
  costMinRule: 0,
  requiredGratuity: 0,
  clientCards: null,
  chargeSendNotification: false,
  notificationModalOpen: false,
  notificationEmail: '',
  notificationType: '',
  notificationTransactionId: null,
  refundingId: null,
  expandedRows: [],
  refundType: 'full',
  refundAmount: '',
  refundFull: '',
  refundDescription: '',
  refundSendNotification: true,
  refundGiftCardAmount: false,
  chargeModalOpen: false,
  chargeOnlyModal: false,
  isModalSubmitting: false,
  isProcessingDelete: false,
  deletingRequest: null,
  formErrors: {},
  saferpayInitialData: {},
  saferpayNameEmpty: true,
  adyenInitialData: {},
  creditCardCollection: CreditCardCollectionOptionsEnum.BOTH_DEFAULT_MANUAL,
  incompletePaylinkAutoCancelMinutesLong: -1,
  incompletePaylinkAutoCancelMinutesShort: 60,
  paylinkRemoveModalTransactionId: null,
  outstandingPaylinkTransaction: null,
  outstandingAddFlowTransaction: null,
  allAddFlowTransactions: [],
  taxGroups: null,
  outstandingPaylink: null,
  paylinkAutoCancel: null,
  enteredReservationType: false,
  venue: null,
  availabilityChargeData: null,
  shiftChargeDataAction: null,
  internalUpsellsEnabled: false,
  venueTimezone: 'US',
  partySize: 2,
  duration: 0,
  defaultGratuity: 0,
  defaultServiceCharge: 0,
  allTransactions: undefined,
  realDatetimeOfSlot: null,
  initDataCache: {
    isLoading: true,
    initType: null, // 'add' | 'edit' | 'clone'
    initTimesUpdate: null,
    initActualUpdate: null,
    allTransactions: null,
  },
  clientSelectGratuity: {
    gratuity: null,
    gratuityClientSelect: false,
    requireGratuityCharge: false,
    applyGratuity: false,
  },
  upgrades: undefined,
}

const calculateTotal = update => {
  const base = update.chargeAmount
  let serviceCharge = update.serviceCharge && update.serviceCharge.replace ? update.serviceCharge.replace(',', '.') : update.serviceCharge
  serviceCharge = serviceCharge || 0
  serviceCharge = update.applyServiceCharge ? money.floatToAmount(parseFloat(serviceCharge)) : money.floatToAmount(0)
  const tax = update.chargeApplyTax ? money.floatToAmount(parseFloat(update.chargeTax || 0)) : money.floatToAmount(0)
  let gratuityCharge =
    update.gratuityCharge && update.gratuityCharge.replace ? update.gratuityCharge.replace(',', '.') : update.gratuityCharge
  gratuityCharge = gratuityCharge || 0
  gratuityCharge = update.applyGratuityCharge ? money.floatToAmount(parseFloat(gratuityCharge)) : money.floatToAmount(0)
  const serviceChargeAmount = money.percent(serviceCharge, base)
  // eslint-disable-next-line no-param-reassign
  update.serviceChargeAmount = serviceChargeAmount
  const subtotal = money.add(base, serviceChargeAmount)
  const taxAmount = money.percent(tax, subtotal)
  // eslint-disable-next-line no-param-reassign
  update.taxAmount = taxAmount
  const tipAmount = money.percent(gratuityCharge, base)
  // eslint-disable-next-line no-param-reassign
  update.gratuityChargeAmount = tipAmount
  let total = money.add(subtotal, taxAmount)
  total = money.add(total, tipAmount)
  if (!update.internalUpsellsEnabled || update.chargeModalOpen) {
    // eslint-disable-next-line no-param-reassign
    update.chargeTotal = total
  }
  return update
}

const getStripeInstance = (currentInstance, cardAction, id, connectedSetupIntents) => {
  if (!window.Stripe || id === null) {
    return null
  }

  const action = cardAction === 'save' ? 'save' : 'take'
  const key = `${action}_${id}_${connectedSetupIntents}`

  if (currentInstance?._key === key) {
    return currentInstance
  }

  const options = action === 'save' && !connectedSetupIntents ? {} : { stripeAccount: id }
  const instance = Stripe(globalInit.venueSettings.stripe_key, options)
  instance._key = key
  return instance
}

const getGratuityType = ({ client_select_gratuity, require_select_gratuity }) => {
  if (client_select_gratuity === true) {
    return require_select_gratuity ? 'require_client_select_charge' : 'client_select_gratuity'
  }
  return 'gratuity_percentage'
}

const getChargesFromTransaction = (transaction, taxGroups) => {
  if (!transaction) {
    return {}
  }
  const taxGroup = transaction.tax && taxGroups?.find(taxGroup => taxGroup.tax_rate === Number(transaction.tax))
  return {
    chargeAmount: money.floatToAmount(transaction.base_amount_decimal ?? ''),
    formattedChargeAmount: money.floatToAmount(transaction.base_amount_decimal ?? ''),
    chargeTotal: money.floatToAmount(transaction.amount_decimal ?? ''),
    chargeApplyTax: !!transaction.tax,
    taxGroupId: taxGroup?.id,
    chargeTax: transaction.tax ?? '',
    applyServiceCharge: !!transaction.service_charge,
    serviceCharge: transaction.service_charge ?? '',
    applyGratuityCharge: !!transaction.gratuity,
    paylinkGratuityType: getGratuityType(transaction),
    gratuityCharge: transaction.gratuity ?? '',
    chargeDescription: transaction.notes ?? '',
  }
}

const isZeroChargeAmount = state => {
  if (state.internalUpsellsEnabled) {
    return money.isZero(state.chargeTotal ?? '')
  }
  return money.isZero(state.chargeAmount ?? '')
}

const getTakePaymentOrSave = (state, canCharge) => {
  let takePaymentOrSave = 'take'
  if (
    state.paymentRule === 'save_for_later' ||
    !canCharge ||
    (state.cardRequired && state.paymentRule === 'advanced_payment' && isZeroChargeAmount(state))
  ) {
    takePaymentOrSave = 'save'
  }
  if (state.internalUpsellsEnabled && !isZeroChargeAmount(state) && canCharge) {
    takePaymentOrSave = 'take'
  }
  if (
    isZeroChargeAmount(state) &&
    ((state.paymentRule !== 'save_for_later' && canCharge) || (!state.cardRequired && state.cardEntryOption === 'paylink'))
  ) {
    takePaymentOrSave = 'none'
  }
  if (state.override) {
    if (state.outstandingAddFlowTransaction) {
      takePaymentOrSave =
        state.outstandingAddFlowTransaction.transaction_type === 'transaction_type_request_info' &&
        !state.outstandingAddFlowTransaction.amount
          ? 'save'
          : 'take'
    } else {
      takePaymentOrSave = 'none'
    }
  }
  return takePaymentOrSave
}

function processChargeAmount(state, amount = 0, transactions = []) {
  const chargeAmountDiff = parseFloat(amount || 0) - getPreviouslyChargedAmount(transactions)
  const chargeAmount = money.floatToAmount(state.internalUpsellsEnabled ? amount || 0 : Math.max(chargeAmountDiff, 0))
  const formattedChargeAmount = priceFormatter.format(chargeAmount, { code: state.currencyCode, format: '%v' })
  return { chargeAmount, formattedChargeAmount, chargeAmountDiff }
}

const getDefaultPaymentDetails = (state, transactions = []) => {
  const outstandingPaylinkTransaction = transactions.find(transaction => {
    if (transaction.transaction_type === TransactionTypes.REQUEST && transaction.paylink_auto_cancel_datetime) {
      return moment.utc().isBefore(moment.utc(transaction.paylink_auto_cancel_datetime))
    }
    return false
  })
  const outstandingAddFlowTransaction = transactions.find(
    transaction => transaction.transaction_type === TransactionTypes.REQUEST && transaction.created_via_res_add_edit
  )
  const allAddFlowTransactions = transactions.filter(transaction => transaction.created_via_res_add_edit)
  let update = {
    ...state,
    outstandingPaylinkTransaction,
    outstandingAddFlowTransaction,
    allAddFlowTransactions,
    ...processChargeAmount(state, state.chargeAmount, transactions),
  }
  update = calculateTotal(update)

  // Prevents awkward race condition during view mode
  if (!state.chargeModalOpen) {
    update.takePaymentOrSave = getTakePaymentOrSave(update, state.venue?.permissions.canCharge)
  }
  if (state.override) {
    update = {
      ...update,
      ...getChargesFromTransaction(outstandingPaylinkTransaction, state.taxGroups),
    }
  }
  return update
}

const getDefaultCharges = (state, rules, paymentRule, transactions) => {
  let charges = {
    chargeAmount: '',
    chargeAmountDiff: 0,
    chargeApplyTax: false,
    applyServiceCharge: false,
    serviceCharge: '',
    applyGratuityCharge: false,
    gratuityCharge: '',
  }
  if (rules.cardRequired && paymentRule === 'advanced_payment') {
    let chargeAmount = rules.costMinRule
    if (rules.chargeType.startsWith('person')) {
      chargeAmount *= state.partySize
    }
    if (rules.chargeType.endsWith('slot')) {
      chargeAmount *= state.duration / 15
    }
    charges = {
      ...processChargeAmount(state, chargeAmount, transactions),
      chargeApplyTax: rules.chargeApplyTax,
      taxGroupId: rules.taxGroupId,
      chargeTax: rules.chargeTax,
      applyServiceCharge: rules.applyServiceCharge,
      serviceCharge: String(rules.serviceCharge || ''),
      applyGratuityCharge: rules.applyGratuityCharge,
      gratuityCharge: String(rules.gratuityCharge || ''),
    }
  }
  return charges
}

function processChargeData(state, action) {
  const { chargeData, partySize, duration, venue, accountId, connectedSetupIntents } = action

  let update = {
    ...state,
    partySize,
    duration,
  }

  const paymentRule = chargeData.partySizeMinRule && partySize <= chargeData.partySizeMinRule ? null : chargeData.paymentRule
  let charges = getDefaultCharges(update, chargeData, paymentRule, state.allTransactions)

  if (state.override) {
    charges = {}
  }
  const cardRequired = !!(chargeData.cardRequired && paymentRule)
  update = {
    ...update,
    ...chargeData,
    ...charges,
    paymentRule,
    cardRequired,
  }

  if (state.override && state.outstandingPaylinkTransaction) {
    update = { ...state }
  }

  update.venue = venue
  update.availabilityChargeData = chargeData

  const takePaymentOrSave = getTakePaymentOrSave(update, venue.permissions.canCharge)

  // Prevents awkward race condition during view mode
  if (!state.chargeModalOpen) {
    update.takePaymentOrSave = takePaymentOrSave
    update.stripeInstance = getStripeInstance(state.stripeInstance, takePaymentOrSave, accountId, connectedSetupIntents)
  }

  if (action.type === ActionTypes.BOOK_AVAILABILITY_GET_TIMES_SUCCESS) {
    update.shiftChargeDataAction = { ...action }
    delete update.shiftChargeDataAction.type
  }

  return calculateTotal(update)
}

function initEnterSlideoutData(state, data) {
  let update = { ...state, initDataCache: { ...state.initDataCache, ...data } }
  const { initType, initTimesUpdate, initActualUpdate, allTransactions } = update.initDataCache
  if ((initType === 'add' || initType === 'clone') && initTimesUpdate) {
    update.initDataCache = { isLoading: false }
    update.cardEntryOption =
      state.creditCardCollection === CreditCardCollectionOptionsEnum.ONLY_PAYLINK ||
      state.creditCardCollection === CreditCardCollectionOptionsEnum.BOTH_DEFAULT_PAYLINK ||
      state.paylinkOnly
        ? 'paylink'
        : 'manual'
    return processChargeData(update, initTimesUpdate)
  }
  if (initType === 'edit' && initTimesUpdate && initActualUpdate && allTransactions) {
    update = { ...update, ...initActualUpdate, allTransactions, initDataCache: { isLoading: false } }
    update = processChargeData(update, initTimesUpdate)
    return getDefaultPaymentDetails(update, allTransactions)
  }
  return update
}

const bookPaymentReducer = (state = initialState, action) => {
  let entryOption
  let selectedCardId
  let takePaymentOrSave
  const cardReset = {
    formattedChargeAmount: currencyFormatter('0.00'),
    chargeAmount: '',
    chargeAmountDiff: 0,
    chargeTotal: '',
    cardHolderName: '',
    cardHolderNumber: '',
    cardExpYear: '',
    cardExpMonth: '',
    cardCcv: '',
    chargeApplyTax: false,
    applyServiceCharge: false,
    applyGratuityCharge: false,
  }
  const defaultEmail = state.actual?.venue_group_client.email_address || state.actual?.email_address || state.notificationEmail

  const getNotificationEmail = cardId => {
    const additionalCard = state.additionalReservationCards.find(i => [i.resCardId, i.resCardFingerprint].includes(cardId))
    if (additionalCard) {
      return additionalCard.notificationEmail || defaultEmail
    }
    return [state.resCardId, state.resCardFingerprint].includes(cardId) ? state.resCardEmail : defaultEmail
  }

  switch (action.type) {
    case GlobalActionTypes.INITIALIZE: {
      Object.assign(initialState, {
        venueTimezone: action.globalInit.venueTimezone,
        creditCardCollection: action.globalInit.venueSettings.credit_card_collection,
        incompletePaylinkAutoCancelMinutesLong: action.globalInit.venueSettings.incomplete_paylink_auto_cancel_minutes_long ?? -1,
        incompletePaylinkAutoCancelMinutesShort: action.globalInit.venueSettings.incomplete_paylink_auto_cancel_minutes_short ?? -1,
        internalUpsellsEnabled: action.globalInit.venueSettings.internal_upsells_enabled,
        paylinkOnly: action.globalInit.venueSettings.paylink_only,
        defaultGratuity: action.globalInit.venueSettings.default_gratuity,
        defaultServiceCharge: action.globalInit.venueSettings.default_service_charge,
      })
      return state
    }

    // Here we clean state before slideout opens:
    // - VIEW_ACTUAL firing after view mode slideout opens
    // - BOOK_SUBMIT_RESERVATION_SUCCESS firing after add new reservation
    // - ENTER_ADD_RESERVATION firing on add new reservation
    // these types should cover all cases when we want to reset state & to not be in the middle of fetching to avoid race conditions
    case ActionTypes.ENTER_ADD_RESERVATION:
    case ActionTypes.VIEW_ACTUAL:
    case ActionTypes.BOOK_SUBMIT_RESERVATION_SUCCESS: {
      const update = {
        ...state,
        ...initialState,
        saferpayInitialData: { ...state.saferpayInitialData, needClean: false },
        adyenInitialData: { ...state.adyenInitialData, needClean: false },
      }
      if (action.type === ActionTypes.ENTER_ADD_RESERVATION) {
        return initEnterSlideoutData(update, { initType: 'add' })
      }
      return update
    }

    case ActionTypes.ENTER_EDIT_RESERVATION: {
      return initEnterSlideoutData(state, { initType: 'edit' })
    }

    case ActionTypes.COPY_VIEW_ACTUAL_DETAILS_TO_BOOK_DETAILS: {
      let savedCard = {}
      let clientCards = []
      const additionalReservationCards = []

      const outstandingPaylink = action.actual.paylink_auto_cancel_datetime
      let cardEntryOption =
        state.creditCardCollection === CreditCardCollectionOptionsEnum.ONLY_PAYLINK ||
        state.creditCardCollection === CreditCardCollectionOptionsEnum.BOTH_DEFAULT_PAYLINK ||
        state.paylinkOnly ||
        (outstandingPaylink && moment.utc().isBefore(moment.utc(outstandingPaylink)))
          ? 'paylink'
          : 'manual'

      const override = action.actual.booked_with_override

      if (action.actual.payments_card_id) {
        savedCard = {
          resCardId: action.actual.payments_card_id,
          resCardLast4: action.actual.payments_last_four,
          resCardType: action.actual.payments_card_type,
          resCardEmail: action.actual.payments_email,
          resCardFingerprint: action.actual.payments_card_fingerprint,
        }
        cardEntryOption = action.actual.payments_card_id
      }

      if (action.actual.additional_reservation_cards) {
        for (const reservation_card of action.actual.additional_reservation_cards) {
          additionalReservationCards.push({
            resCardId: reservation_card.card_id,
            resCardLast4: reservation_card.last_four,
            resCardType: reservation_card.card_type,
            resCardFingerprint: reservation_card.card_fingerprint,
            notificationEmail: reservation_card.email,
          })
        }
      }

      if (action.actual.venue_group_client.credit_cards) {
        clientCards = action.actual.venue_group_client.credit_cards
      }

      const update = {
        ...savedCard,
        clientCards,
        additionalReservationCards,
        cardEntryOption,
        notificationEmail: action.actual.venue_group_client.email_address,
        override,
        useGuestProfilePhoneNumber: !!action.actual.phone_number_formatted,
        outstandingPaylink,
        paylinkAutoCancel: action.actual.paylink_auto_cancel_minutes,
        taxGroups: action.venue.bookSettings.taxGroups,
        actual: action.actual,
        venue: action.venue,
      }

      return initEnterSlideoutData({ ...state, ...update }, { initActualUpdate: update })
    }

    case ActionTypes.LOAD_TRANSACTIONS_SUCCESS: {
      const allTransactions = action.transactionResult.charges || []
      return initEnterSlideoutData(state, { allTransactions })
    }

    case ActionTypes.ENTER_CLONE_RESERVATION: {
      const update = {
        ...state,
        ...initialState,
        // availability timeslots might be loaded already, so we want to preserve initDataCache
        initDataCache: state.initDataCache,
        stripeInstance: state.stripeInstance,
      }
      return initEnterSlideoutData(update, { initType: 'clone' })
    }

    case ActionTypes.CHARGE_FORM_VALIDATED:
      return { ...state, formErrors: action.formErrors }

    case ActionTypes.RESTORE_SHIFT_CHARGES:
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_CHARGES_FROM_EXTERNAL_TIME_SLOT:
    case ActionTypes.BOOK_AVAILABILITY_GET_TIMES_SUCCESS: {
      const update = processChargeData(state, action)
      return initEnterSlideoutData(update, { initTimesUpdate: action })
    }

    case ActionTypes.PAYMENT_ADD_RES_CARD:
      if (!state.resCardId) {
        return {
          ...state,
          resCardId: action.value.card_id,
          resCardLast4: action.value.last_four,
          resCardType: action.value.card_type,
          resCardEmail: action.value.email,
          resCardFingerprint: action.value.card_fingerprint,
          notificationEmail: action.value.email,
        }
      }
      if (action.value.billing_history_id) {
        const { additionalReservationCards } = state
        additionalReservationCards.push({
          resCardId: action.value.card_id,
          resCardLast4: action.value.last_four,
          resCardType: action.value.card_type,
          resCardFingerprint: action.value.card_fingerprint,
          notificationEmail: action.value.email,
        })
        return {
          ...state,
          additionalReservationCards,
        }
      }
      return {
        ...state,
      }

    case ActionTypes.PAYMENT_DELETE_CARD_START:
      return { ...state, isProcessingDelete: true }

    case ActionTypes.PAYMENT_DELETE_CARD_FAIL:
      return { ...state, isProcessingDelete: false }

    case ActionTypes.PAYMENT_DELETE_CARD_SUCCESS: {
      const updatedAdditionalReservationCards = action.value.additional_reservation_cards.map(reservationCard => ({
        resCardId: reservationCard.card_id,
        resCardLast4: reservationCard.last_four,
        resCardType: reservationCard.card_type,
        notificationEmail: reservationCard.email,
      }))

      return {
        ...state,
        resCardId: action.value.payments_card_id,
        resCardLast4: action.value.payments_last_four,
        resCardType: action.value.payments_card_type,
        resCardEmail: action.value.payments_email,
        resCardFingerprint: action.value.payments_fingerprint,
        additionalReservationCards: updatedAdditionalReservationCards,
        isProcessingDelete: false,
      }
    }

    case ActionTypes.PAYMENT_PAYLINK_DELETE_START:
      return { ...state, deletingRequest: action.value }

    case ActionTypes.PAYMENT_PAYLINK_DELETE_FAIL:
      return { ...state, deletingRequest: null }

    case ActionTypes.PAYMENT_PAYLINK_DELETE_SUCCESS:
      return { ...state, deletingRequest: null }

    case ActionTypes.PAYMENT_SET_REFUND_ID: {
      const fullRefundAmount = money.floatToAmount(action.transaction?.amount_remaining_decimal)
      return {
        ...state,
        refundingId: action.transaction?.id,
        refundAmount: fullRefundAmount,
        refundFull: fullRefundAmount,
        notificationEmail: !action.transaction
          ? defaultEmail
          : action.transaction?.email || getNotificationEmail(action.transaction?.card_id),
      }
    }

    case ActionTypes.BOOK_CLIENT_CHANGE_SELECTED_CLIENT: {
      const selectedClient = _.isObject(action.selectedClient) ? { ...action.selectedClient } : null
      const cards = selectedClient ? selectedClient.credit_cards || null : null

      return { ...state, clientCards: cards, useGuestProfilePhoneNumber: !!selectedClient?.phone_number_formatted }
    }

    case ActionTypes.BOOK_CLIENT_CHANGE_CLIENT_FIELD:
      return { ...state, useGuestProfilePhoneNumber: !(action?.field === 'phone_number_formatted' && !action?.value) }

    case ActionTypes.PAYMENT_CHANGE_OVERRIDE: {
      const { actual, outstandingPaylinkTransaction, taxGroups, allTransactions } = state
      const charges = action.value ? getChargesFromTransaction(outstandingPaylinkTransaction, taxGroups) : {}

      let { takePaymentOrSave } = state
      let update = {
        ...state,
        override: action.value,
      }
      let defaultPaymentDetails = {}

      if (action.value) {
        takePaymentOrSave = 'none'
      } else if (!actual && state.cardRequired) {
        update = {
          ...update,
          ...getDefaultCharges(state, state.availabilityChargeData, state.paymentRule, state.allTransactions),
        }
        defaultPaymentDetails = getDefaultPaymentDetails(update, allTransactions)
        takePaymentOrSave = defaultPaymentDetails.takePaymentOrSave
      } else if (state.internalUpsellsEnabled) {
        takePaymentOrSave = getTakePaymentOrSave(update, state.venue?.permissions.canCharge)
      }

      return {
        ...update,
        ...charges,
        ...defaultPaymentDetails,
        takePaymentOrSave,
      }
    }

    case ActionTypes.PAYMENT_CHANGE_USE_GUEST_PROFILE_PHONE_NUMBER: {
      return { ...state, useGuestProfilePhoneNumber: action.value }
    }

    case ActionTypes.PAYMENT_CHANGE_PHONE_COUNTRY: {
      return { ...state, cardPhoneCountry: action.value }
    }

    case ActionTypes.PAYMENT_CHANGE_PHONE_NUMBER: {
      return { ...state, cardPhoneNumber: action.value }
    }

    case ActionTypes.PAYMENT_CHANGE_CARD_ENTRY_OPTION:
      selectedCardId = ['paylink', 'manual'].indexOf(action.value) < 0 ? action.value : null
      return {
        ...state,
        selectedCardId,
        cardEntryOption: action.value,
      }

    case ActionTypes.PAYMENT_TOGGLE_DETAIL: {
      let expanded = []
      if (state.expandedRows.indexOf(action.value) !== -1) {
        expanded = _.without(state.expandedRows, action.value)
      } else {
        expanded = _.concat(state.expandedRows, action.value)
      }
      return { ...state, expandedRows: expanded }
    }

    case ActionTypes.PAYMENT_TOGGLE_MODAL_CHARGE: {
      let { value } = action
      if (typeof value === 'undefined') {
        value = !state.chargeModalOpen
      }

      const chargeOnly = action.restrict === 'chargeOnly'
      if (chargeOnly) {
        entryOption = action.card.resCardId
      } else if (action.paylinkOnly) {
        entryOption = 'paylink'
      } else {
        entryOption =
          state.creditCardCollection === CreditCardCollectionOptionsEnum.ONLY_PAYLINK ||
          state.creditCardCollection === CreditCardCollectionOptionsEnum.BOTH_DEFAULT_PAYLINK
            ? 'paylink'
            : 'manual'
      }

      selectedCardId = chargeOnly ? action.card.resCardId : null
      takePaymentOrSave = action.canCharge ? 'take' : 'save'

      return {
        ...state,
        ...cardReset,
        paymentRule: null,
        takePaymentOrSave,
        stripeInstance: getStripeInstance(state.stripeInstance, takePaymentOrSave, action.accountId, action.connectedSetupIntents),
        cardEntryOption: entryOption,
        chargeModalOpen: value,
        chargeOnlyModal: chargeOnly,
        selectedCardId,
        cardRequired: false,
        notificationEmail: !value ? defaultEmail : action.card?.notificationEmail || defaultEmail, // 11
        useGuestProfilePhoneNumber: !!state.actual?.phone_number_formatted,
      }
    }

    case ActionTypes.PAYMENT_TOGGLE_MODAL_NOTIFICATION:
      return {
        ...state,
        notificationTransactionId: action.transaction?.id,
        notificationType: action.notificationType,
        notificationModalOpen: !!action.transaction,
        notificationEmail: !action.transaction
          ? defaultEmail
          : action.transaction?.email || getNotificationEmail(action.transaction?.card_id),
      }

    case ActionTypes.PAYMENT_NOTIFICATION_START:
      return { ...state, isModalSubmitting: true }

    case ActionTypes.PAYMENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isModalSubmitting: false,
        notificationModalOpen: false,
      }

    case ActionTypes.PAYMENT_NOTIFICATION_FAIL:
      return { ...state, isModalSubmitting: false }

    case ActionTypes.PAYMENT_REFUND_START:
      return { ...state, isModalSubmitting: true }

    case ActionTypes.PAYMENT_REFUND_SUCCESS:
      return { ...state, refundingId: null, isModalSubmitting: false }

    case ActionTypes.PAYMENT_REFUND_FAIL:
      return { ...state, isModalSubmitting: false }

    case ActionTypes.PAYMENT_CHARGE_START:
      return { ...state, isModalSubmitting: true }

    case ActionTypes.PAYMENT_CHARGE_SUCCESS:
      return {
        ...state,
        ...cardReset,
        chargeModalOpen: false,
        isModalSubmitting: false,
      }

    case ActionTypes.PAYMENT_CHARGE_FAIL:
      return { ...state, isModalSubmitting: false }

    case ActionTypes.PAYMENT_CHANGE_CARD_HOLDER_NAME:
      return { ...state, cardHolderName: action.value }

    case ActionTypes.PAYMENT_CHANGE_CARD_HOLDER_NUMBER:
      return { ...state, cardHolderNumber: action.value }

    case ActionTypes.PAYMENT_CHANGE_CARD_EXP_MONTH:
      return { ...state, cardExpMonth: action.value }

    case ActionTypes.PAYMENT_CHANGE_CARD_EXP_YEAR:
      return { ...state, cardExpYear: action.value }

    case ActionTypes.PAYMENT_CHANGE_CARD_CCV:
      return { ...state, cardCcv: action.value }

    case ActionTypes.PAYMENT_CHANGE_TAKE_PAYMENT_OR_SAVE:
      return {
        ...state,
        takePaymentOrSave: action.value,
        stripeInstance: getStripeInstance(state.stripeInstance, action.value, action.accountId, action.connectedSetupIntents),
      }

    case ActionTypes.PAYMENT_CHANGE_CHARGE_AMOUNT: {
      const formattedChargeAmount = currencyFormatter(action.value)
      const chargeAmount = money.floatToAmount(formattedChargeAmount.replace(',', '.'))
      return calculateTotal({
        ...state,
        formattedChargeAmount,
        chargeAmount,
      })
    }

    case ActionTypes.PAYMENT_CHANGE_CHARGE_APPLY_TAX: {
      const firstTaxGroup = action.taxGroups[0]
      const updatedApplyTaxFields = {
        chargeApplyTax: action.value,
        taxGroupId: action.value ? firstTaxGroup.id : null,
        chargeTax: action.value ? firstTaxGroup.tax_rate : null,
      }
      return calculateTotal({ ...state, ...updatedApplyTaxFields })
    }

    case ActionTypes.PAYMENT_CHANGE_TAX_GROUP_ID: {
      const taxGroup = action.taxGroups.find(obj => obj.id === action.value)
      return calculateTotal({
        ...state,
        taxGroupId: action.value,
        chargeTax: taxGroup.tax_rate,
      })
    }

    case ActionTypes.PAYMENT_CHANGE_APPLY_SERVICE_CHARGE:
      return calculateTotal({
        ...state,
        applyServiceCharge: action.value,
      })

    case ActionTypes.PAYMENT_CHANGE_SERVICE_CHARGE:
      return calculateTotal({ ...state, serviceCharge: action.value })

    case ActionTypes.PAYMENT_CHANGE_APPLY_GRATUITY_CHARGE:
      return calculateTotal({
        ...state,
        applyGratuityCharge: action.value,
      })

    case ActionTypes.PAYMENT_CHANGE_GRATUITY_CHARGE:
      return calculateTotal({ ...state, gratuityCharge: action.value })

    case ActionTypes.PAYMENT_CHANGE_PAYLINK_GRATUITY_TYPE:
      if (action.value !== 'gratuity_percentage') {
        return calculateTotal({
          ...state,
          paylinkGratuityType: action.value,
          gratuityCharge: '',
        })
      }
      return { ...state, paylinkGratuityType: action.value }

    case ActionTypes.PAYMENT_CHANGE_PAYMENTS_FORM: {
      const update = { ...state, ...action.value }
      if (isZeroChargeAmount(state) !== isZeroChargeAmount(update) && !state.override) {
        update.takePaymentOrSave = getTakePaymentOrSave(update, state.venue?.permissions.canCharge)
      }
      if (state.realDatetimeOfSlot || state.actual) {
        let paylinkAutoCancel = state.outstandingPaylinkTransaction
          ? state.actual.paylink_auto_cancel_minutes
          : state.incompletePaylinkAutoCancelMinutesLong
        const fromReservationToNowMinutes = moment(state.realDatetimeOfSlot || state.actual.date_arrival_time_dt_sync_dt_formatted).diff(
          getVenueLocalTime(state.venueTimezone),
          'minutes'
        )
        // Use the short auto-cancel time if we're already under the long time
        paylinkAutoCancel =
          fromReservationToNowMinutes < paylinkAutoCancel ? state.incompletePaylinkAutoCancelMinutesShort : paylinkAutoCancel
        // If we're already under the short time too, disable auto-cancel by default
        paylinkAutoCancel = fromReservationToNowMinutes < paylinkAutoCancel ? -1 : paylinkAutoCancel
        update.paylinkAutoCancel = paylinkAutoCancel
      }
      return update
    }

    case ActionTypes.PAYMENT_CHANGE_CHARGE_DESCRIPTION:
      return { ...state, chargeDescription: action.value }

    case ActionTypes.PAYMENT_CHANGE_PAYLINK_AUTO_CANCEL:
      return { ...state, paylinkAutoCancel: action.value }

    case ActionTypes.RECEIVE_TOKEN:
      return { ...state, cardToken: action.token, cardData: action.data }

    case ActionTypes.PAYMENT_CHANGE_CHARGE_SEND_NOTIFICATION:
      return { ...state, chargeSendNotification: action.value }

    case ActionTypes.PAYMENT_CHANGE_CHARGE_NOTIFICATION_EMAIL:
      return { ...state, notificationEmail: action.value }

    case ActionTypes.REFUND_CHANGE_CHARGE_AMOUNT:
      return { ...state, refundAmount: action.value }

    case ActionTypes.REFUND_CHANGE_TYPE:
      return {
        ...state,
        refundType: action.value,
        refundAmount: action.value === 'full' ? state.refundFull : state.refundAmount,
      }

    case ActionTypes.REFUND_CHANGE_DESCRIPTION:
      return { ...state, refundDescription: action.value }

    case ActionTypes.REFUND_TOGGLE_NOTIFICATION:
      return { ...state, refundSendNotification: action.value }

    case ActionTypes.REFUND_TOGGLE_REFUND_GIFT_CARD:
      return { ...state, refundGiftCardAmount: action.value }

    case ActionTypes.PASS_STRIPE_CARD_ELEMENT:
      return { ...state, stripeCardElement: action.element }

    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SELECTED_TIME_SLOT: {
      const stripeInstance =
        action.venue.paymentType === AccountTypes.STRIPE
          ? getStripeInstance(state.stripeInstance, state.takePaymentOrSave, action.accountId, action.connectedSetupIntents)
          : null
      let paylinkAutoCancel = state.outstandingPaylinkTransaction
        ? state.actual.paylink_auto_cancel_minutes
        : state.incompletePaylinkAutoCancelMinutesLong
      if (action.selectedTimeSlot) {
        const fromReservationToNowMinutes = moment(action.selectedTimeSlot.real_datetime_of_slot).diff(
          getVenueLocalTime(state.venueTimezone),
          'minutes'
        )
        // Use the short auto-cancel time if we're already under the long time
        paylinkAutoCancel =
          fromReservationToNowMinutes < paylinkAutoCancel ? state.incompletePaylinkAutoCancelMinutesShort : paylinkAutoCancel
        // If we're already under the short time too, disable auto-cancel by default
        paylinkAutoCancel = fromReservationToNowMinutes < paylinkAutoCancel ? -1 : paylinkAutoCancel
      }
      return {
        ...state,
        stripeCardElement: stripeInstance ? state.stripeCardElement : null,
        realDatetimeOfSlot: action.selectedTimeSlot ? action.selectedTimeSlot.real_datetime_of_slot : null,
        stripeInstance,
        paylinkAutoCancel,
      }
    }
    case ActionTypes.SET_SAFERPAY_INITIAL_DATA:
      return {
        ...state,
        saferpayInitialData: { ...action.data },
      }
    case ActionTypes.SET_ADYEN_INITIAL_DATA:
      return {
        ...state,
        adyenInitialData: { ...action.data },
      }
    case ActionTypes.CLOSE_SLIDEOUT:
      return {
        ...state,
        saferpayInitialData: { ...state.saferpayInitialData, needClean: true },
        adyenInitialData: { ...state.adyenInitialData, needClean: true },
        enteredReservationType: false,
      }
    case ActionTypes.SAFERPAY_SET_EMPTY_HOLDERNAME_STATUS:
      return {
        ...state,
        saferpayNameEmpty: action.isEmpty,
      }
    case ActionTypes.SHOW_REMOVE_PAYLINK_MODAL:
      return {
        ...state,
        paylinkRemoveModalTransactionId: action.transactionId,
      }
    case ActionTypes.REFRESH_ACTUAL_SUCCESS: {
      return {
        ...state,
        actual: action.actual,
        override: action.actual?.booked_with_override ?? false,
      }
    }
    default:
      return state
  }
}

export default bookPaymentReducer
