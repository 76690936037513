import { useEffect, useMemo } from 'react'
import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormPercentageInput } from '@sevenrooms/core/ui-kit/form'
import { paymentMessages } from '../Payment.locales'
import { ChargeLine } from './ChargeLine'
import type { ChargesForm, ClientSelectGratuityForm } from '../Payment.zod'

interface GratuityProps {
  amount: number | null
  field: Field<ChargesForm>
  oldValue?: number | null
  override: boolean
  selectable?: boolean
  currencyCode: string
  clientSelectGratuity: Field<ClientSelectGratuityForm>
  forceDisableEdit?: boolean // need for beta
}

export function Gratuity({
  amount,
  field,
  oldValue,
  selectable,
  currencyCode,
  override,
  forceDisableEdit,
  clientSelectGratuity,
}: GratuityProps) {
  const { formatMessage } = useLocales()

  const [gratuityValue, applyGratuity, gratuityClientSelect] = useWatchMany(field, ['gratuity', 'applyGratuity', 'gratuityClientSelect'])
  const [cGratuity, cGratuityClientSelect] = useWatchMany(clientSelectGratuity, ['gratuity', 'gratuityClientSelect'])

  const isShowInput = !forceDisableEdit && ((override && !selectable) || applyGratuity) && !gratuityClientSelect
  // disable checkbox for gratuity if client select gratuity is on even though gratuity is "selectable"
  const isSelectable = !gratuityClientSelect && !cGratuityClientSelect && selectable

  const gratuity = useMemo(() => {
    if (!applyGratuity) {
      return 0
    }

    return (cGratuityClientSelect && gratuityClientSelect ? cGratuity : gratuityValue) ?? 0
  }, [cGratuity, cGratuityClientSelect, gratuityClientSelect, gratuityValue, applyGratuity])
  const chargeAmount = amount ? amount * (gratuity / 100) : 0

  useEffect(() => {
    // Allow clientSelectGratuityForm field to set gratuity value if true
    if (cGratuityClientSelect && gratuityClientSelect) {
      field.prop('gratuity').set(gratuity)
    }
  }, [field, gratuity, cGratuityClientSelect, gratuityClientSelect])

  return (
    <ChargeLine
      title={formatMessage(paymentMessages.gratuity)}
      field={isSelectable ? field.prop('applyGratuity') : undefined}
      testId="sr-supafly-apply-gratuity-charge"
      oldAmount={oldValue != null && oldValue !== gratuity ? oldValue : null}
      amount={chargeAmount}
      currencyCode={currencyCode}
    >
      {isShowInput && (
        <FormPercentageInput
          data-test="sr-supafly-gratuity-charge"
          field={field.prop('gratuity')}
          fieldHeight="s"
          hideErrorMessage
          disabled={(!override && !selectable) || (!override && !selectable && gratuityClientSelect)}
        />
      )}
    </ChargeLine>
  )
}
